<template>
  <div class="container">
    <header-bar></header-bar>
    <div class="gl_filter bg_white">
      <el-form :inline="true" class="form">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="资讯状态">
              <el-select
                v-model="formData.state"
                clearable
                placeholder="请选择状态"
              >
                <el-option value="" label="全部"></el-option>
                <el-option
                  v-for="item in courseState"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="资讯标题">
              <el-input
                v-model="formData.newsTitle"
                clearable
                placeholder="请输入资讯标题"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button
              class="btn btn_blue"
              icon="el-icon-search"
              @click="search"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="封面图" prop="imgUrl" width="190">
          <template slot-scope="scope">
            <img :src="scope.row.imgUrl" class="hh_pic" />
          </template>
        </el-table-column>
        <el-table-column label="资讯标题" prop="newsTitle" />
        <el-table-column label="浏览" prop="viewCount" width="100" />
        <el-table-column label="创建时间" prop="submitTime" width="170" />
        <el-table-column label="发布状态" prop="state" width="110">
        </el-table-column>
        <el-table-column label="分类" prop="newsType" width="120" />
        <el-table-column label="操作" width="190">
          <template slot-scope="scope">
            <span class="operate color_blue" @click="watchFist(scope.row.id)"
              >预览</span
            >
            <span class="operate color_blue" @click="getAuditLogList(scope.row)"
            >审核记录</span
            >
            <span
              v-if="scope.row.state == '待审核'"
              class="operate color_orange"
              @click="check(scope.row.id)"
              >审核</span
            >
            <span
              v-if="scope.row.state == '已发布'"
              class="operate color_red"
              @click="toDown(scope.row.id)"
              >下架</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      class="dialog_wrapper"
      v-if="isReviewDialogVisible"
      :visible.sync="isReviewDialogVisible"
      :close-on-click-modal="false"
      width="680px"
    >
      <check-detail
        :courseId="courseId"
        :type="type"
        @changeDialogClose="closeDialog"
      ></check-detail>
    </el-dialog>
    <el-drawer
      :visible.sync="isDrawerVisible"
      :with-header="false"
      :wrapperClosable="true"
    >
      <div class="gl_drawer">
        <div class="gl_drawer_title">
          <span
            class="gl_state"
            :class="{
              color_orange: currentState == '待审核',
              color_green: currentState == '已发布',
              color_red: currentState == '已驳回',
              color_gray: currentState == '已下架',
            }"
          >{{ currentStateName }}</span
          >
          <h2 :title="lecturerTitle">{{ lecturerTitle }}</h2>
        </div>
        <div class="gl_drawer_cont">
          <p class="gl_drawer_t">审批历史</p>
          <ul v-if="auditCount > 0">
            <li v-for="item in auditList" :key="item.logId">
              <span class="gl_audit_user">{{ item.operatorName }}</span
              >{{
                item.operation
              }}<span class="gl_audit_time">{{ item.operateTime }}</span>
              <p v-if="item.remark" class="gl_audit_info">
                "{{ item.remark }}"
              </p>
            </li>
          </ul>
          <p v-else class="gl_empty">——— 暂无审批数据 ———</p>
        </div>
        <pagination
          class="hh_page"
          v-if="auditCount > recordData.pageSize"
          :pageSize="recordData.pageSize"
          :total="auditCount"
          @pageChange="auditPageChange"
          ref="child"
        />
      </div>
    </el-drawer>
    <pagination
      v-if="totalCount > 10"
      :pageSize="formData.pageSize"
      :total="totalCount"
      @pageChange="pageChange"
      ref="child"
    />
  </div>
</template>
<script>
import API from "@/apis/backStageEndAPI/consultManageAPI";
import CommonUtils from "@/utils";
import headerBar from "@comps/backStageComponent/layout/headerBar/headerBar";
import Pagination from "@comps/backStageComponent/pagination";
import checkDetail from "./component/checkDetail";
export default {
  name: "draftBox",
  components: { Pagination, checkDetail, headerBar },
  data() {
    return {
      type: "consult",
      courseId: "",
      isReviewDialogVisible: false,
      isDrawerVisible: false,
      totalCount: 0,
      tableData: [],
      courseState: [
        //资讯状态
        { id: 0, label: "未发布" },
        { id: 1, label: "已发布" },
        { id: 2, label: "待审核" },
        { id: 3, label: "已驳回" },
        { id: 9, label: "已下架" },
      ],
      formData: {
        page: 1,
        pageSize: 10,
        newsTitle: "",
        state: "",
      },
      recordData: {
        page: 1,
        pageSize: 10,
        itemId: '',
        itemType: 1//资讯
      },
      auditCount: 0,
      currentRow: "",
      auditList: [],
      currentState: "",
      currentStateName: "",
      teaId: '',
      auditType: false,
      lecturerTitle: "",
    };
  },
  mounted() {
    this.getConsultList();
  },
  methods: {
    check(id) {
      this.courseId = id;
      this.isReviewDialogVisible = !this.isReviewDialogVisible;
    },
    toDown(id) {
      this.$confirm("确定要下架该资讯吗？", {})
        .then((res) => {
          let params = {
            id: id,
            state: 9,
          };
          API.testConsult(params).then((res) => {
            this.getConsultList();
          });
        })
        .catch(() => {});
    },
    closeDialog() {
      this.isReviewDialogVisible = false;
      this.courseId = "";
      this.getConsultList();
    },
    watchFist(id) {
      //预览
      this.$router.push({ path: "/previewConsult", query: { id: id } });
    },
    search() {
      //搜索
      this.formData.page = 1;
      if (this.totalCount > 10) {
        this.$refs.child.handleSize(1);
        this.getConsultList();
      } else {
        this.getConsultList(); //获取列表
      }
    },
    getConsultList() {
      API.list(CommonUtils.parseToParams(this.formData)).then((res) => {
        res.dataList.map((item) => {
          switch (item.state) {
            case 0:
              item.state = "未发布";
              break;
            case 1:
              item.state = "已发布";
              break;
            case 2:
              item.state = "待审核";
              break;
            case 3:
              item.state = "已驳回";
              break;
            case 9:
              item.state = "已下架";
          }
        });
        this.tableData = res.dataList;
        this.totalCount = res.rowCount;
      });
    },
    async getAuditLogList(item) {//获取审核记录
      this.currentRow = item;
      this.currentState = item.state;
      this.currentStateName = item.state
      this.lecturerTitle = item.newsTitle;
      this.recordData.itemId = item.id
      let data = await API.getAuditLogList(
        CommonUtils.parseToParams(this.recordData)
      );
      this.auditList = data.dataList;
      this.auditCount = data.rowCount;
      this.isDrawerVisible = true;
    },
    auditPageChange(val) {
      //审核记录页面跳转
      this.recordData.page = val;
      this.getAuditLogList(this.currentRow);
    },
    pageChange(val) {
      //页面跳转
      this.formData.page = val;
      this.getConsultList();
    },
  },
};
</script>
<style lang="scss" scoped>
.color_gray {
  color: #dcdcdc;
}
.gl_filter {
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 12px;
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}
.gl_drawer {
  width: 100%;
  box-sizing: border-box;
  .gl_drawer_title {
    position: relative;
    padding: 0 40px 0 70px;
    background: var(--colorBlue);
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    h2 {
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .gl_state {
      position: absolute;
      top: 50%;
      left: 8px;
      padding: 4px 8px;
      border-radius: 16px;
      background: #fff;
      font-size: 12px;
      line-height: 100%;
      transform: translateY(-50%);
    }
  }
  .gl_empty {
    color: #666;
    text-align: center;
  }
  li {
    margin-bottom: 16px;
    padding: 8px;
    background: #f7faff;
    .gl_audit_user {
      padding-right: 6px;
      color: var(--colorBlue);
    }
    .gl_empty {
      color: #666;
      text-align: center;
    }
    li {
      margin-bottom: 16px;
      padding: 8px;
      background: #f7faff;
      .gl_audit_user {
        padding-right: 6px;
        color: var(--colorBlue);
      }
      .gl_audit_time {
        float: right;
        color: #666;
      }
      .gl_audit_info {
        padding: 4px;
      }
    }
  }
}
.hh_pic {
  width: 60px;
  height: 60px;
}
</style>
